import React, { FC, SetStateAction, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { DOCTREEN_ROUTE } from "../../tools/constants";
import server from "../../tools/server";
import getRapportsPrecedents from "./findPreviousReport";
const { v4: uuidv4 } = require('uuid');

interface OwnProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setData: React.Dispatch<SetStateAction<any>>;
  setReportingInfo: React.Dispatch<SetStateAction<any>>;
  saveCR: (reportingInfo: any) => void
}

const IframeModal: FC<OwnProps> = ({ setOpen, setData, setReportingInfo, saveCR }) => {
  const { treeId, reportId, technique } = useParams<{ treeId: string, reportId: string, technique: string }>();
  const examen = useSelector((state: RootState) => state.user.user?.examens[0]);
  const [reportingSourceIds, setReportingSourcesIds] = useState<string[]>([]);

  const handleClose = () => {
    if (extId) {
      const fetchResponse = async () => {
        try {
          const url = `${process.env.REACT_APP_SERVER_IFRAME}${DOCTREEN_ROUTE}/sessions?client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&ext_id=${extId}`;
          const requestOptions: RequestInit = {
            method: 'GET',
          };
          let response = await fetch(url, requestOptions);
          const text = await response.json();
          if (text) {
            const resultingDocument = text[0].resultingDocument;
            setData(resultingDocument.text);
            setReportingInfo({
              title: resultingDocument.originTitle,
              treeId: resultingDocument.lineTreeId,
              reportId: resultingDocument.reportId,
              reportingId: resultingDocument._id,
              reportingSourceIds
            })
            setTimeout(() => {

              saveCR({
                title: resultingDocument.originTitle,
                treeId: resultingDocument.lineTreeId,
                reportId: resultingDocument.reportId,
                reportingId: resultingDocument._id,
                reportingSourceIds
              })
            }, 100)
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchResponse();
    }
    setOpen(false);
  };


  const [url, setUrl] = useState<string | null>(null);
  const [extId, setExtId] = useState<string | null>(null);
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    const ext_id = uuidv4();
    setExtId(ext_id);
    const fetchData = async () => {
      try {
        const examenId = examen ? examen?.id : ''; // Remplacez par la valeur réelle de examenId
        const reports = treeId ? getRapportsPrecedents(examenId, treeId) : [];

        // Construction des paramètres de requête
        const params = new URLSearchParams();
        params.append('examenId', examenId);
        if (reports) {
          reports.forEach((report, index) => {
            if (report.tree !== '') {
              params.append(`reports[${index}][treeId]`, report.tree);
              params.append(`reports[${index}][reportId]`, report.report);
            }
          });
        }
        const requestOptions1: RequestInit = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: 'Bearer ' + token,
          },
        };
        const connexion1 = await fetch(
          server + '/docs/getPrevious?' + params,
          requestOptions1,
        );
        const response1 = await connexion1.json();
        const reportingsIds = response1.data;
        setReportingSourcesIds(reportingsIds);
        const bodyContent = JSON.stringify({
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          code: examen?.quoting,
          tree_id: treeId,
          prefill_id: reportId,
          doc: technique ? [] : reportingsIds,
          doc_labels: reportingsIds,
          ext_id
        });
        const requestOptions: RequestInit = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json; charset=UTF-8' },
          body: bodyContent,
        };
        const response = await fetch(`${process.env.REACT_APP_SERVER_IFRAME}${DOCTREEN_ROUTE}/reporting/init`, requestOptions);
        const text = await response.text();
        if (text) {
          setUrl(text)
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [treeId, examen]);

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.origin !== process.env.REACT_APP_SERVER_IFRAME) {
        return;
      }
      const { action } = event.data;
      if (action === 'document_saved') {
        handleClose();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [extId]);

  return (
    <div className="iframe-box position-relative">
      <IconButton className="position-absolute close-icon" color="error" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      {url ? <iframe id='doctreen-app' className="doctreen-iframe" src={url}></iframe> : null}
    </div>
  );


}

export default IframeModal;
